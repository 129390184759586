@import '~@angular/material/theming';

* {
  box-sizing: border-box;
}

:root {
  --hs-red-color: rgb(232, 86, 0);
  --hs-green-color: rgb(50, 182, 67);
  --hs-orange-color: rgb(255, 183, 0);
  --hs-gray-color: rgb(89.45%, 90.7%, 89.29%);

  --elevation-stroke: 0 0 0 1px rgba(4, 4, 5, 0.15);
  --elevation-low: 0 1px 0 rgba(4, 4, 5, 0.2), 0 1.5px 0 rgba(6, 6, 7, 0.05), 0 2px 0 rgba(4, 4, 5, 0.05);
  --elevation-medium: 0 4px 4px rgba(0, 0, 0, 0.16);
  --elevation-high: 0 8px 16px rgba(0, 0, 0, 0.24);
}

.mat-tooltip {
  &.mat-tooltip {
    background-color: white;
    color: black;
    font-size: 0.8rem;
    max-width: 500px;
    border: 1px solid black;
  }
}

.mat-select-panel {
  max-height: 350px !important;
}

html body {
  cursor: default;
}

.fill-space {
  flex: 1 1 auto;
}

// === Fix icon alignment
button.mat-menu-item {
  line-height: 24px !important;
}

a {
  text-decoration: none;
  color: mat-color($mat-blue, 500);

  &:visited {
    color: mat-color($mat-purple, 500);
  }
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

.info-panel .mat-icon svg {
  height: 32px;
  width: 32px;
}

// ===

.mat-dialog-container {
  padding: 0 !important;
  margin: 0 !important;
}

.gridster-item-inner {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
}

.ui-table-wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
}

.mde-popover-panel {
  width: 100%;
}

mat-form-field {
  width: 100%;

  .mat-form-field-wrapper {
    margin: 0;
  }
}

.mat-row:hover {
  background-color: #e0e0e0;
}

.highcharts-container,
.highcharts-container svg {
  width: 100% !important;
  height: 100% !important;
}

// paddings
@media screen and (min-width: 600px) {
  // small
  .pl-sm-8 {
    padding-left: 8px !important;
  }
  .pr-sm-8 {
    padding-right: 8px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// Custom snackbars styles
.green-snackbar {
  background-color: mat-color($mat-gray, 200) !important;
  color: mat-color($mat-green, 500) !important;
  font-weight: 500;
}
.green-snackbar button {
  background-color: mat-color($mat-green, 500) !important;
  color: white !important;
  border: none;
}
.red-snackbar {
  background-color: mat-color($mat-gray, 200) !important;
  color: mat-color($mat-red, 500) !important;
  font-weight: 500;
}
.red-snackbar button {
  background-color: mat-color($mat-red, 500) !important;
  color: white !important;
  border: none;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
  max-height: 100vh !important;
}
